import { lazy, Suspense } from "react";

import { Screen } from "../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../stackflow/routes/types";
import { MaintenanceModeSocialCapitalBoundary } from "../../../widgets/social-capital/MaintenanceModeSocialCapitalBoundary";
const AppBarSocialCapitalPoints = lazy(
  () => import("./AppBarSocialCapitalPoints"),
);

type PathParams = {};
type QueryParams = {};

export type ActivityLocalProfileContributeParams = PathParams & QueryParams;

const ActivityLocalProfileContributeLayout: ActivityLayoutComponentType<
  ActivityLocalProfileContributeParams
> = ({ children }) => {
  return (
    <Screen
      appBar={{
        renderRight: () => (
          <Suspense>
            <MaintenanceModeSocialCapitalBoundary fallback={null}>
              <AppBarSocialCapitalPoints />
            </MaintenanceModeSocialCapitalBoundary>
          </Suspense>
        ),
      }}
    >
      {children}
    </Screen>
  );
};

export default ActivityLocalProfileContributeLayout;
