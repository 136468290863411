/**
 * 동네가이드 브랜드명
 */
export const SOCIAL_CAPITAL_NAME = "동네가이드";
/**
 * 동네가이드 깃발 아이콘
 */
export const SOCIAL_CAPITAL_ICON =
  "https://asset-town.krrt.io/production/illust/32f47006-eda9-41f7-ae85-d6834c8f06e2/13a40be2031d8df542d95f153dd333d94cf0f054.webp";
/**
 * 동네가이드 깃발 아이콘 (simplified)
 */
export const SOCIAL_CAPITAL_ICON_SIMPLIFIED =
  "https://assetstorage.krrt.io/1024506612556180326/8be74359-3653-464c-bff4-75872a5a3ea6/width=48,height=48.webp";
/**
 * 동네가이드 FAQ URL
 */
export const SOCIAL_CAPITAL_FAQ_URL = "https://www.daangn.com/wv/faqs/15054";
