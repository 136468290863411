import { captureException } from "@sentry/react";
import { BottomSheet } from "@stackflow/plugin-basic-ui";
import { ErrorBoundary } from "react-error-boundary";

import { userInfoStore } from "../../../../../entities/user/model/userInfoStore";
import { EmptyOrError } from "../../../../../shared/ui/empty-or-error/EmptyOrError";
import { BottomFixedButton } from "../../../../../shared/ui/layout/BottomFixedButton";
import { type ActivityLayoutComponentType } from "../../../../../stackflow/routes/types";
import { useNavigator } from "../../../../../stackflow/useNavigator";
import * as css from "./ActivitySocialCapitalContributionPointsDetails.css";

interface PathParams {
  profile_id: string;
  contribution_points_id: string;
}
export interface ActivitySocialCapitalContributionPointsDetailsParams
  extends PathParams {}
const ActivitySocialCapitalContributionPointsDetailsLayout: ActivityLayoutComponentType<
  ActivitySocialCapitalContributionPointsDetailsParams
> = ({ children }) => {
  const { pop } = useNavigator();

  return (
    <BottomSheet>
      <BottomFixedButton
        actions={[
          {
            size: "xlarge",
            type: "button",
            elementType: "button",
            variant: "primary",
            children: "확인",
            onClick: () => {
              pop();
            },
          },
        ]}
      >
        <ErrorBoundary
          fallbackRender={({ error }) => {
            const userId = userInfoStore.getState().user?.id;

            captureException(error, {
              user: { id: userId },
            });

            return (
              <div className={css.base}>
                <EmptyOrError body="에러가 발생했어요" />
              </div>
            );
          }}
        >
          {children}
        </ErrorBoundary>
      </BottomFixedButton>
    </BottomSheet>
  );
};

export default ActivitySocialCapitalContributionPointsDetailsLayout;
