import { SOCIAL_CAPITAL_NAME } from "../../../../entities/social-capital";
import { useRedirectUserProfileEffect } from "../../../../features/user-profile/model/useRedirectUserProfileEffect";
import { Screen } from "../../../../shared/ui/screen/Screen";
import { type ActivityLayoutComponentType } from "../../../../stackflow/routes/types";

type PathParams = {
  /**
   * 프로필 ID
   */
  profile_id: string;
};
type QueryParams = {};
export type ActivityUserContributionPointsHistoryParams = PathParams &
  QueryParams;

const ActivityUserContributionPointsHistoryLayout: ActivityLayoutComponentType<
  ActivityUserContributionPointsHistoryParams
> = ({ children, params }) => {
  useRedirectUserProfileEffect({
    activityName: "profile.contribution_points_history",
    profileUserId: params.profile_id,
  });

  return (
    <Screen appBar={{ title: `${SOCIAL_CAPITAL_NAME} 점수`, border: false }}>
      {children}
    </Screen>
  );
};

export default ActivityUserContributionPointsHistoryLayout;
