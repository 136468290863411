const review = {
  all: ["review"] as const,
  lists: () => [...review.all, "list"] as const,
  list: (filters: string | number) => [...review.lists(), { filters }] as const,
  details: () => [...review.all, "detail"] as const,
  detail: (id: number) => [...review.details(), id] as const,
};

const reviewTag = {
  all: ["review-tag"] as const,
  details: () => [...reviewTag.all, "detail"] as const,
  detail: (id?: number) => [...reviewTag.details(), id] as const,
};

const region = {
  all: ["region"] as const,
  lists: () => [...region.all, "list"] as const,
  list: (filters: string | number) => [...region.lists(), { filters }] as const,
  details: () => [...region.all, "detail"] as const,
  detail: (id: number | "bridge") => [...region.details(), id] as const,
};

const user = {
  all: ["user"] as const,
  details: () => [...user.all, "detail"] as const,
  detail: (id: number | "bridge") => [...user.details(), id] as const,
};

const businessAccount = {
  all: ["business-account"] as const,
  lists: () => [...businessAccount.all, "list"] as const,
  list: (filters: string | number) =>
    [...businessAccount.lists(), { filters }] as const,
  details: () => [...businessAccount.all, "detail"] as const,
  detail: (id: number | "bridge") =>
    [...businessAccount.details(), id] as const,
};

const userContributionSuggestion = {
  all: ["user-contribution-suggestion"] as const,
  lists: () => [...userContributionSuggestion.all, "list"] as const,
  list: (filters: string | number) =>
    [...userContributionSuggestion.lists(), { filters }] as const,
  details: () => [...userContributionSuggestion.all, "detail"] as const,
  detail: (id: number) =>
    [...userContributionSuggestion.details(), id] as const,
};

const userContributedLocalProfile = {
  all: ["user-contributed-local-profile"] as const,
  lists: () => [...userContributedLocalProfile.all, "list"] as const,
  list: (filters: string | number) =>
    [...userContributedLocalProfile.lists(), { filters }] as const,
  details: () => [...userContributedLocalProfile.all, "detail"] as const,
  detail: (id: number | "bridge") =>
    [...userContributedLocalProfile.details(), id] as const,
};

const localProfile = {
  all: ["local-profile"] as const,
  lists: () => [...localProfile.all, "list"] as const,
  list: (filters: string | number) =>
    [...localProfile.lists(), { filters }] as const,
  details: () => [...localProfile.all, "detail"] as const,
  detail: (id: number) => [...localProfile.details(), id] as const,
  isReviewable: ({
    localProfileId,
    userId,
  }: {
    localProfileId: number;
    userId: number;
  }) =>
    [...localProfile.detail(localProfileId), "is-reviewable", userId] as const,
};

const socialCapital = {
  all: ["social-capital"] as const,
  pointSystem: () => [...socialCapital.all, "point-system"] as const,
};

const socialCapitalUser = {
  all: [...socialCapital.all, "social-capital-user"] as const,
  details: () => [...socialCapitalUser.all, "detail"] as const,
  detail: (id: number) => [...socialCapitalUser.details(), id] as const,
};

export const queryKeys = {
  review,
  reviewTag,
  region,
  user,
  businessAccount,
  userContributionSuggestion,
  localProfile,
  userContributedLocalProfile,
  socialCapital,
  socialCapitalUser,
};
