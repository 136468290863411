import clsx from "clsx";
import { type PropsWithChildren } from "react";

import * as css from "./Flex.css";

export type FlexProps = css.BaseVariants & {
  onClick?: () => void;
  className?: string;
};
export const Flex = ({
  children,
  className,
  onClick,
  ...styleProps
}: PropsWithChildren<FlexProps>) => {
  return (
    <div className={clsx(css.base(styleProps), className)} onClick={onClick}>
      {children}
    </div>
  );
};
