import { receive, send } from "@stackflow/compat-await-push";
import {
  type ActivityComponentType,
  useActions,
  useActivity,
} from "@stackflow/react";
import { useCallback, useMemo } from "react";

import { useReferrerAndEntry } from "../entities/referrer/lib";
import { bridge } from "../shared/lib/karrot-bridge/bridge";
import { openMinikarrotWebview as _openMinikarrotWebview } from "../shared/lib/webview-link-router/webviewLinkRouter";
import { addQueryParams } from "../shared/utils/url/addQueryParams";
import { type ExtractLazyComponentParams } from "./routes/types";
import { type Activities, type TypeUseFlow } from "./Stack";

/**
 * `stackflow()` 함수에서 반환받은 `useFlow()` 함수를 그대로 써도 큰 문제는 없지만,
 * 해당 함수를 쓰게 되면 런타임에 Circular Dependency가 발생하게 돼요.
 * 따라서, 자동완성을 위해 타입만 가져다쓰고 런타임은 의존하지 않도록 useActions를 활용해 useFlow를 다시 생성합니다.
 * https://stackflow.so/advanced/fix-use-flow
 */
const useFlow: TypeUseFlow = () => useActions();

export const useNavigator = () => {
  const { id } = useActivity();
  const { isRoot } = useActivity();
  const { push, replace, pop } = useFlow();
  const {
    currentScreenName,
    incomingReferrer,
    incomingEntry,
    outgoingReferrer,
    outgoingEntry,
  } = useReferrerAndEntry();

  const pushScheme = useCallback((scheme: string) => {
    window.location.href = scheme;
  }, []);

  const asyncPushScheme = useCallback(
    (scheme: string) =>
      new Promise<void>((resolve) => {
        const onVisibilityChange = () => {
          if (document.visibilityState === "visible") {
            window.removeEventListener("visibilitychange", onVisibilityChange);
            resolve();
          }
        };
        window.addEventListener("visibilitychange", onVisibilityChange);

        pushScheme(scheme);
      }),
    [pushScheme],
  );

  type ModifiedPush = <
    K extends Parameters<typeof push>["0"],
    T extends Activities,
  >(
    activityName: K,
    params: T[K] extends
      | ActivityComponentType<infer U>
      | { component: ActivityComponentType<infer U> }
      ? U
      : Record<string, unknown>,
    options?: Parameters<typeof push>["2"] & { intermediate?: boolean },
  ) => ReturnType<typeof push>;

  const modifiedPush: ModifiedPush = useCallback(
    (activityName, params, options) => {
      const { intermediate, ...pushOptions } = options || {};

      return push(
        activityName,
        {
          referrer: incomingReferrer,
          entry:
            intermediate && incomingEntry ? incomingEntry : currentScreenName,
          ...params,
        },
        pushOptions,
      );
    },
    [push, incomingReferrer, incomingEntry, currentScreenName],
  );

  const asyncPush = useCallback(
    <
      R extends any,
      K extends keyof Activities = keyof Activities,
      P extends ExtractLazyComponentParams<
        Activities[K]
      > = ExtractLazyComponentParams<Activities[K]>,
    >(
      activityName: K,
      params: P,
      options?: Parameters<typeof push>[2] & { intermediate?: boolean },
    ) =>
      receive<R | undefined>(
        modifiedPush(activityName, params as any, options),
      ),
    [modifiedPush],
  );

  type ModifiedReplace = <
    K extends Parameters<typeof replace>["0"],
    T extends Activities,
  >(
    activityName: K,
    params: T[K] extends
      | ActivityComponentType<infer U>
      | { component: ActivityComponentType<infer U> }
      ? U
      : Record<string, unknown>,
    options?: Parameters<typeof replace>["2"] & { intermediate?: boolean },
  ) => ReturnType<typeof replace>;

  const modifiedReplace: ModifiedReplace = useCallback(
    (activityName, params, options) => {
      const { intermediate, ...replaceOptions } = options || {};

      return replace(
        activityName,
        {
          referrer: incomingReferrer,
          entry:
            intermediate && incomingEntry ? incomingEntry : currentScreenName,
          ...params,
        },
        replaceOptions,
      );
    },
    [replace, incomingReferrer, incomingEntry, currentScreenName],
  );

  const modifiedPop = useMemo(
    () => (isRoot ? () => bridge.closeRouter({}) : pop),
    [isRoot, pop],
  );

  const asyncPop = useCallback(
    (...args: Partial<Parameters<typeof pop>>) => {
      pop(...(args as []));

      return {
        send: (data: any) => {
          send({
            activityId: id,
            data,
          });
        },
      };
    },
    [id, pop],
  );

  const openMinikarrotWebview = useCallback(
    (args: Parameters<typeof _openMinikarrotWebview>["0"]) => {
      const url = addQueryParams(args.url, {
        referrer: outgoingReferrer,
        entry: outgoingEntry,
      });

      return _openMinikarrotWebview({ ...args, url });
    },
    [outgoingEntry, outgoingReferrer],
  );

  const replaceRouter = useCallback(
    (args: { url: string }) => {
      const url = addQueryParams(args.url, {
        referrer: outgoingReferrer,
        entry: outgoingEntry,
      });

      return bridge.replaceRouter({
        router: { remote: url, navbar: false, scrollable: false },
      });
    },
    [outgoingEntry, outgoingReferrer],
  );

  return useMemo(
    () => ({
      pushScheme,
      asyncPushScheme,
      asyncPush,
      asyncPop,
      push: modifiedPush,
      replace: modifiedReplace,
      pop: modifiedPop,
      openMinikarrotWebview,
      replaceRouter,
    }),
    [
      pushScheme,
      asyncPushScheme,
      asyncPush,
      asyncPop,
      modifiedPush,
      modifiedReplace,
      modifiedPop,
      openMinikarrotWebview,
      replaceRouter,
    ],
  );
};
